<template>
    <div>
        <div style="display: none;" id="pdf_div">
            <printPlanningComponent />
        </div>

        <v-container fluid class="pa-0">
            <v-row>
                <v-col cols="12" class="pb-0">
                    <div class="configureBtn">
                        <v-btn small depressed @click="print" class="mr-2 bg-gradient">
                            <v-icon small class="mr-2">$print_s</v-icon>
                            {{ $t('print') }}
                        </v-btn>
                        <v-menu offset-y class="action-buttons ml-3">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" small depressed v-bind="attrs" v-on="on" v-if="$func.hasRight('lead/config_planning')">
                                    <v-icon small class="mr-2">$settings_s</v-icon>
                                    {{ $t('configure') }}
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item dense link class="" @click="dialogHours = true" v-if="$func.hasRight('operation/update')">
                                    <v-list-item-title dense link class="action-menu">
                                        {{ $t('opeHours') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item dense link @click="openTeamAttribution = true">
                                    <v-list-item-title dense class="action-menu">
                                        {{ $t('vendorPlanning') }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item dense link>
                                    <v-list-item-title dense @click="getForm('operation/'+operationId+'/getForm?formName=editCreneau')" class="action-menu">
                                        {{ $t('creneauDuration') }}
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <teamAttributionPopup v-if="operations && operations[0]" :operation="operations[0]" :operationId="this.$route.params.operation_id" @close="closeTeamAttribution" :openTeamAttribution="openTeamAttribution" />
                    <Planning 
                        type="DISPLAY_OPERATION_RDV"
                        :genericUnavailable="genericUnavailable"
                        :operationId="this.$route.params.operation_id"
                        :operations="operations"
                        :appointements="appointements"
                        :creneau="creneau"
                        @refreshRdvList="refreshRdvList"
                        @getPlanningIndispo="getPlanningIndispo"
                        @addRdv="addRdv"
                        :key="'planning_'+planningKey"
                        mode="inner"/>
                </v-col>
            </v-row>
            <v-dialog
                v-model="dialogHours"
                fullscreen
                transition="dialog-bottom-transition"
                scrollable
                persistent
                @keydown.esc="cancel"
                content-class="vdialogPlanning"
                >
                    <v-card tile>
                    <v-toolbar flat dark class="bg-gradient">
                            <v-btn
                                depressed
                                text
                                color="white"
                                class="square transparent mr-2"
                                @click.native="cancel"
                            >
                                <v-icon dark small>$prev</v-icon>
                            </v-btn>

                        <v-toolbar-title>{{ $t('operationHours') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn outlined class="mr-3" @click="cancel" color="white">Annuler</v-btn>
                        <v-btn outlined color="white" @click="recordHours">Enregistrer</v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <Planning ref="planning" type="OPERATION_HOURS"
                            :genericUnavailable="genericUnavailable2"
                            :isMine="false"
                            :creneau="60"
                            :operations="operations"
                            :appointements="[]"
                            :entityId="entityId"
                            :operationId="operationId"
                            @addItem="addItem"
                            @removeUnavailable="removeUnavailable"
                            @removeAllUnavailable="removeAllUnavailable"
                            @addAllUnavailable="addAllUnavailable"
                            mode="popup"
                            />
                    </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="closedOperationDialog" width="400">
                    <v-card tile>
                        <v-toolbar flat dark class="bg-gradient">
                            <v-toolbar-title>{{ $t('warning') }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="closedOperationDialog = false">
                                <v-icon>$close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="pt-5">
                            {{$t('canttakerdvonclosedoperation')}}
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <FormDialogComponent v-if="form" ref="componentForm" :type="'edit'" :form="form" :action="'SET_DATA'" @launchFunction="launchFunction"/>
        </v-container>
    </div>
</template>
<script>
import Planning from '@/components/planning/Planning'
import GenericDataService from '@/services/GenericDataService'
import planningMixins from '@/mixins/planningMixins'
import getForm from '@/mixins/mixins';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import dateManipulation from '@/mixins/dateManipulation'
import teamAttributionPopup from '../operationsComponents/teamAttributionPopup.vue'
import printPlanningComponent from '../operationsComponents/printPlanningComponent.vue'
import { mapActions } from "vuex";
export default {
    name:"OperationsDetailPlanning",
    components:{Planning, teamAttributionPopup, FormDialogComponent, printPlanningComponent},
    mixins:[planningMixins, dateManipulation, getForm],
    data() {
        return {
            genericUnavailable: [],
            operations:[],
            appointements:[],
            creneau:"",
            entityId:1,
            openTeamAttribution: false,
            dialogHours: false,
            publicPath:process.env.BASE_URL,
            hoursReversed: {},
            loading:false,
            daysdate:null,
            operationId: this.$route.params.operation_id,
            genericUnavailable2: [],
            form:null,
            planningKey:0,
            closedOperationDialog: false
        }
    },
   
     created() {
        this.operations = []
        GenericDataService.getData('/operation/'+this.$route.params.operation_id + '/get').then((response) => {
            this.operations.push(response.data.data)
            if(this.operations[0] && this.operations[0].config && this.operations[0].config.slot_duration)
                this.creneau = this.operations[0].config.slot_duration
           
            GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                this.hoursReversed = response.data.data.hours_text2
                this.genericUnavailable2 = response.data.data.hours
                this.loading = false
            }).catch((e) => {
                this.loading = false
            })
        })
    },
    methods: {
        ...mapActions("alerts", ["setAlert"]),
        closeTeamAttribution(){
            this.planningKey++
            this.openTeamAttribution = false
        },
        customRefresh(){
            GenericDataService.getData('/operation/'+this.$route.params.operation_id + '/get').then((response) => {
                this.operations= [response.data.data]
                if(this.operations[0] && this.operations[0].config && this.operations[0].config.slot_duration)
                    this.creneau = this.operations[0].config.slot_duration
            })
        },
        launchFunction($event){
            this[$event]();
        },
        cancel: function(){
            GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                this.hoursReversed = response.data.data.hours_text2
                this.genericUnavailable2 = response.data.data.hours
            })
            this.dialogHours = false
        },
        recordHours(){
            GenericDataService.postData('/operation/'+this.operationId+'/setHours', this.genericUnavailable2).then((response) =>{
                GenericDataService.getData('/operation/'+this.operationId+'/getHours').then((response) => {
                    this.hoursReversed = response.data.data.hours_text2
                    this.genericUnavailable2 = response.data.data.hours
                    this.genericUnavailable = this.$func.hasRight('lead/update_duration') ? [] : response.data.data.hours_reversed
                    this.dialogHours = false
                })
            })
        },
        addRdv(item){
            if(this.operations && this.operations[0] && this.operations[0].status && this.operations[0].status == 'CLOSED'){
                this.$store.dispatch('ADD_ERROR', {errorMessage : "canttakerdvonclosedoperation", type: "user"});
                return;
            }
            let rdv = {}
            rdv.type = "RDV"
            rdv.date_start = item.date+" "+item.start
            rdv.date_end = item.date+" "+item.end
            rdv.operation_id = this.operations[0].id
            if(this.$store.state.auth.currentUser.loggedAs){
                rdv.user_id = this.$store.state.auth.currentUser.loggedAs.id
            } else {
                rdv.user_id = this.$store.state.auth.currentUser.profile.id
            }
            rdv.place = item.place
            rdv.status = 'DRAFT'
            rdv.visio = "0"
            rdv.links = {}
                       
            if(item.ref_id){
                rdv.links.user = [parseInt(item.ref_id)]
            }
            let payload = {};
                payload.operation_id = this.operations[0].id;
                payload.entity_id = item.place.config.entity_id[0];
                payload.rdv = rdv

            GenericDataService.postData('/superlead/saveStep1', payload).then((response) => {
                this.$store.dispatch("lead/SET_SUPERLEAD_AND_OPEN_DIALOG", response.data.data);
            })
        },
        refreshRdvList(vendorIds){
            // GenericDataService.getData('/operation/'+this.$route.params.operation_id+'/getRdvList').then((response) => {
            //     this.appointements = response.data.data
            // })
        },
        getPlanningIndispo(payload) {
            GenericDataService.postData('/operation/'+this.$route.params.operation_id+'/getRdvList', payload).then((response) => {
                this.appointements = response.data.data
            })
            GenericDataService.postData('/operation/' + this.$route.params.operation_id + '/getDispos'+(this.$func.hasRight('lead/update_duration') ? '?allWeek=true': ''), payload).then((response) => {
                this.genericUnavailable = this.$func.hasRight('lead/update_duration') ? [] : response.data.data.hours_reversed
            });
        },
        addItem:function(event, unavailableData) {
           //let slot = this.genericUnavailable.find((e) => e.day == unavailableData.day)
            let item = this._.cloneDeep(unavailableData);
            item.start = this.parseFloatToHour(this.parseHourToFloat(unavailableData.start))
            item.end = this.parseFloatToHour(this.parseHourToFloat(unavailableData.end))
            //check si une indispo est présente avant et apres
            let itemBefore = this.genericUnavailable2.find(
                (e) => (e.day == unavailableData.day &&
                          (
                              this.parseHourToFloat(e.end) == this.parseHourToFloat(item.start) ||
                              (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start)
                                  && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end)
                                  && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                          )
                        )
            );
            let itemAfter = this.genericUnavailable2.find(
              (e) => (e.day == unavailableData.day &&
                        (
                            this.parseHourToFloat(e.start) == this.parseHourToFloat(item.end) ||
                            (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                        )
                      )
                );
            let itemUnder = this.genericUnavailable2.filter((e) => (e.day == unavailableData.day && this.parseHourToFloat(e.start) >= this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) <= this.parseHourToFloat(item.end) && e.end != "00:00"))
            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.genericUnavailable2.findIndex((e) => e.day == unavailableData.day && e.start == item.end);
                itemBefore.end = itemAfter.end
                this.genericUnavailable2.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                itemBefore.end = item.end
            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++) {
                    let itemUnderIndex = this.genericUnavailable2.findIndex((e) => e.day == unavailableData.day && e.start == itemUnder[index].start && e.end == itemUnder[index].end)
                    this.genericUnavailable2.splice(itemUnderIndex, 1)
                }
                this.genericUnavailable2.push(item);
            } else { // sinon on le push
                this.genericUnavailable2.push(item);
            }
        },
        //Suppression d'une indispo
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable2.findIndex((e) => e.day == slot.day && e.start == slot.start)
            //let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            this.genericUnavailable2.splice(dayItem, 1)
        },
        removeAllUnavailable() {

        },
        addAllUnavailable(){

        },
        print() {
            window.print();
        },
    },
}
</script>
<style lang="scss">
    .configureBtn {
        position:absolute;
        right:15px;
        top:15px;
    }
</style>